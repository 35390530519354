import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Lang } from '@app/i18n/lang.type';
import { DocumentDetails, DocumentValue } from '../models';

export const documentActions = createActionGroup({
    source: 'Document',
    events: {
        'Get Document': props<{ documentNo: string; lang: Lang }>(),
        'Get Document Success': props<{ item: DocumentDetails }>(),
        'Get Document Error': props<{ documentNo: string }>(),

        'Get Financial Document': props<{ documentNo: string; lang: Lang }>(),
        'Get Financial Document Success': props<{ item: DocumentDetails }>(),
        'Get Financial Document Error': props<{ documentNo: string }>(),

        'Update Document': props<{ documentNo: string; value: DocumentValue[] }>(),
        'Update Document Success': props<{ item: DocumentDetails }>(),
        'Update Document Error': emptyProps(),

        'Open Pdf': props<{ documentNo: string; lang: Lang }>(),
        'Open Pdf Success': props<{ blob: Blob }>(),
        'Open Pdf Error': emptyProps(),

        'Reorder Products': props<{ documentNo: string }>(),
        'Reorder Products Success': emptyProps(),
        'Reorder Products Error': emptyProps(),

        Clear: emptyProps(),
    },
});
